var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id',{ initialValue:'0' }]),expression:"['id',{ initialValue:'0' }]"}],attrs:{"name":"id"}})],1),_c('a-form-item',{attrs:{"label":"姓名","required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'truename',
          {rules: [{ required: true, message: '请输入姓名' }]}
        ]),expression:"[\n          'truename',\n          {rules: [{ required: true, message: '请输入姓名' }]}\n        ]"}],attrs:{"name":"truename","placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sex', { initialValue: 1 }]),expression:"['sex', { initialValue: 1 }]"}],attrs:{"name":"sex"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("男")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("女")])],1)],1),_c('a-form-item',{attrs:{"label":"电话号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {rules: [{ required: true, message: '请输入电话号码' }]}
        ]),expression:"[\n          'mobile',\n          {rules: [{ required: true, message: '请输入电话号码' }]}\n        ]"}],attrs:{"name":"mobile","placeholder":"请输入电话号码"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1),_c('a-form-item',{attrs:{"label":"地区","prop":"regionId"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'regionId'
        ]),expression:"[\n          'regionId'\n        ]"}],attrs:{"name":"regionId","field-names":_vm.RegreplaceFields,"options":_vm.RegtreeData,"allow-clear":"","placeholder":"请选择"},on:{"change":_vm.handleRegionChange}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remarks',
          {rules: [{message: '请输入备注' }]}
        ]),expression:"[\n          'remarks',\n          {rules: [{message: '请输入备注' }]}\n        ]"}],attrs:{"name":"remarks","placeholder":"请输入备注"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }