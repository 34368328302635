<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="id"
      >
        <a-input
          v-decorator="['id',{ initialValue:'0' }]"
          name="id"></a-input>
      </a-form-item>
      <a-form-item
        label="姓名"
        :required="true"
      >
        <a-input
          v-decorator="[
            'truename',
            {rules: [{ required: true, message: '请输入姓名' }]}
          ]"
          name="truename"
          placeholder="请输入姓名"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="性别"
      >
        <a-radio-group v-decorator="['sex', { initialValue: 1 }]" name="sex">
          <a-radio :value="1">男</a-radio>
          <a-radio :value="2">女</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="电话号码"
      >
        <a-input
          v-decorator="[
            'mobile',
            {rules: [{ required: true, message: '请输入电话号码' }]}
          ]"
          name="mobile"
          placeholder="请输入电话号码"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="地区" prop="regionId">
        <a-cascader
          v-decorator="[
            'regionId'
          ]"
          name="regionId"
          :field-names="RegreplaceFields"
          :options="RegtreeData"
          allow-clear
          placeholder="请选择"
          @change="handleRegionChange"
        />
      </a-form-item>
      <a-form-item
        label="备注"
      >
        <a-textarea
          v-decorator="[
            'remarks',
            {rules: [{message: '请输入备注' }]}
          ]"
          name="remarks"
          placeholder="请输入备注"/>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  import { allList as nosodochiumList } from '@/api/nosodochium'
  import { cityList as RegAllList } from '@/api/region'
  import { Cascader as ACascader } from 'ant-design-vue'
  export default {
    components: {
        ACascader
    },
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this),
        RegreplaceFields: { label: 'name', value: 'regionId', children: 'children' },
        RegtreeData: []
      }
    },
      mounted () {
          this.getRegAllList()
      },
    methods: {
        getRegAllList () {
            RegAllList().then(res => {
                this.RegtreeData = res.result
                this.$forceUpdate()
            })
        },
        getNosodochiumList (params) {
            nosodochiumList(params).then(res => {
                this.nosodata = res.result
                this.$forceUpdate()
            })
        },
        handleRegionChange (value) {
            this.form.setFieldsValue({
                nosoId: undefined,
                nosoDepId: undefined
            })
            this.getNosodochiumList({ 'regionIds': value.join(',') })
        }
    }
  }
</script>
